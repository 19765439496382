import {
  Container,
  Stack,
} from '@chakra-ui/react';



export default function Projects() {
  return (
    <Container maxW={'2xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        py={{ base: 20, md: 14 }}>
          

      </Stack>
    </Container>
  );
}
;